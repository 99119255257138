<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field has-addons">
          <div class="control has-icons-left has-icons-right">
            <input class="input"
              v-model="filter.query"
              type="text"
              placeholder="Start typing to filter"
              @input="search">
            <span class="icon is-left">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
          </div>
          <div class="control">
            <a class="button is-outlined tooltip"
              :class="[ filter.butterflyCode ? 'is-warning' : 'is-primary' ]"
              :data-tooltip="filter.butterflyCode ? 'Active Butterfly search' : 'Butterfly search'"
              @click="butterflySearch()">
              <span class="icon">
                <i class="fqi fqi-butterfly fqi-20px" />
              </span>
            </a>
          </div>
          <div class="control">
            <a class="button is-primary is-outlined tooltip"
              data-tooltip="Reset search"
              @click="reset()">
              <span class="icon">
                <i class="mdi mdi-close mdi-24px" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="column is-narrow"
        style="display: flex; align-items: center">
        <div class="field-body">
          <div class="field is-narrow">
            <input class="is-checkradio"
              id="labourScroll"
              type="checkbox"
              :checked="$userInfo.scrollItemLabour"
              @change="$setScrollItemLabour"
              name="labourScroll">
            <label for="labourScroll">Scroll on add</label>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field is-horizontal is-pulled-right">
          <div class="field-body">
            <div v-if="labourTimeTypes.RACQ && selectedTimeType === labourTimeTypes.RACQ.replace('-', '').toLowerCase()"
              class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select v-model="selectedRacqBodySize"
                    @change="getAvailableLabours()">
                    <option value="A">A Size</option>
                    <option value="B">B Size</option>
                    <option value="W">Wagon</option>
                    <option value="4">4x4</option>
                    <option value="V">Van</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="labourTimeTypes.WESF && selectedTimeType === labourTimeTypes.WESF.replace('-', '').toLowerCase()"
              class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select v-model="selectedWesfBodySize"
                    @change="getAvailableLabours()">
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select v-model="selectedTimeType"
                    @change="getAvailableLabours()">
                    <option v-for="(labourTime, propertyName, index) in labourTimeTypes"
                      :key="index"
                      :value="labourTime.replace('-', '').toLowerCase()">
                      {{ labourTime }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="labourTimeTypes.eMTA && selectedTimeType === labourTimeTypes.eMTA.replace('-', '').toLowerCase()"
              class="field is-narrow">
              <div class="control">
                <a class="button tooltip is-tooltip-topright"
                  @click="emtaTest"
                  data-tooltip="Change e-MTA vehicle">
                  <span class="icon">
                    <i class="mdi mdi-car" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr v-for="(item, index) in availableItems"
        :key="index"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(index, $event)">
        <td>{{ item.itemNo }}</td>
        <td @dblclick="addNewItems(item, index)">{{ item.description }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">
          <a class="button is-primary is-small is-inverted"
            @click="addNewItem(item)">
            <span class="icon is-medium">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
    <div>
      <butterfly-modal v-if="isButterflyModalActive"
        :active.sync="isButterflyModalActive"
        v-model="selectedButterflyCodes"
        @filterButterfly="butterflySearch"
        @reset="reset"
        @done="filterButterly"
        @cancel="cancel">
        <div slot="text-content">
          <butterfly v-if="isButterflyModalActive"
            v-model="selectedButterflyCodes" />
        </div>
      </butterfly-modal>
    </div>
    <emta-registration-modal v-if="isEmtaRegistrationModalActive"
      :active.sync="isEmtaRegistrationModalActive"
      icon-name="mdi-account-plus"
      icon-type="primary"
      @register="registerEmta"
      @cancel="cancelEmta" />
    <emta-vehicle-selection-modal v-if="isEmtaVehicleSelectionModalActive"
      :active.sync="isEmtaVehicleSelectionModalActive"
      v-model="emtaVehicle"
      icon-name="mdi-car"
      icon-type="primary"
      :make="make"
      :model="model"
      :year="year"
      @select="selectEmtaVehicle"
      @cancel="cancelEmtaVehicle" />
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import BulmaTable from '@/components/BulmaTable'
import { ItemCategoryTypes, LabourTimeTypes } from '@/enums'
import { AvailableLabourColumns } from './columns'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { Butterfly, ButterflyModal } from '@/components/Butterfly'
import StoreMixin from '@/views/quote/storeMixin'
import QuoteService from '@/views/quote/QuoteService'
import { EmtaRegistrationModal, EmtaVehicleSelectionModal } from '@/components/emta'
import EmtaService from '@/components/emta/EmtaService'

export default {
  name: 'QuoteTemplateAvailableLabours',
  components: {
    BulmaTable,
    Butterfly,
    ButterflyModal,
    EmtaRegistrationModal,
    EmtaVehicleSelectionModal
  },
  mixins: [NumberFiltersMixin, StoreMixin],
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    scheduleId: {
      type: String,
      default: ''
    },
    bodyId: {
      type: String,
      default: ''
    },
    make: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    year: {
      type: Number,
      default: 0
    },
    labourType: {
      type: String,
      default: LabourTimeTypes.NRMA.replace('-', '').toLowerCase()
    },
    isLtar: {
      type: Boolean,
      default: false
    },
    isNtar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      emtaVehicle: {
        makeId: '',
        modelId: '',
        year: null,
        variantId: '',
        nvic: ''
      },
      filter: {
        countryId: Guid.empty(),
        companyId: Guid.empty(),
        query: '',
        butterflyCode: '',
        scheduleId: '',
        bodyId: this.bodyId || '',
        bodySize: '',
        paintGroup: '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 8
      },
      availableItems: null,
      selectedButterflyCodes: [],
      addedItems: [],
      selectedTimeType: this.labourType.toLowerCase(),
      isButterflyModalActive: false,
      selectedRacqBodySize: 'A',
      selectedWesfBodySize: 'Medium',
      isEmtaRegistrationModalActive: false,
      isEmtaVehicleSelectionModalActive: false
    }
  },
  computed: {
    columns() {
      return AvailableLabourColumns
    },
    labourTimeTypes() {
      if (this.isLtar) {
        return { LTAR: 'LTAR' }
      }

      if (this.isNtar) {
        return { NTAR: 'NTAR' }
      }

      var timesTypes = {}
      Object.keys(LabourTimeTypes).forEach(timesType => {
        if (timesType !== 'NTAR' && timesType !== 'LTAR') {
          timesTypes[timesType] = LabourTimeTypes[timesType]
        }
      })
      return timesTypes
    },
    newItems() {
      return this.value.filter(i => i.isNew && !i.deleted)
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    validEmtaVehicle() {
      return this.emtaVehicle.variantId !== '' && this.emtaVehicle.makeId !== '' && this.emtaVehicle.modelId !== '' && this.emtaVehicle.year !== 0
    }
  },
  watch: {
    async labourType(val) {
      if (val) {
        this.selectedTimeType = val.toLowerCase()
        await this.getAvailableLabours()
      }
    },
    async bodyId(val) {
      if (val) {
        this.filter.bodyId = val
        await this.getAvailableLabours()
      }
    }
  },
  async created() {
    this.filter.companyId = this.$userInfo.companyId
    this.emtaVehicle = _cloneDeep(this.emtaVehicleStore)
    this.getAvailableLabours()
  },
  methods: {
    async getAvailableLabours() {
      let registered = true
      if (this.labourTimeTypes.RACQ && this.selectedTimeType === this.labourTimeTypes.RACQ.replace('-', '').toLowerCase()) {
        this.filter.bodySize = this.selectedRacqBodySize
      } else if (this.labourTimeTypes.WESF && this.selectedTimeType === this.labourTimeTypes.WESF.replace('-', '').toLowerCase()) {
        this.filter.bodySize = this.selectedWesfBodySize
      } else if (this.labourTimeTypes.eMTA && this.selectedTimeType === this.labourTimeTypes.eMTA.replace('-', '').toLowerCase()) {
        registered = await this.isEmtaRegistered()
        if (!this.validEmtaVehicle && registered) {
          this.isEmtaVehicleSelectionModalActive = true
          return
        } else if (this.validEmtaVehicle && registered) {
          this.isTableLoading = true
          this.filter.nvic = this.emtaVehicle.nvic
          this.filter.variantId = this.emtaVehicle.variantId
          if (!this.filter.scheduleId) {
            this.filter.scheduleId = `U${this.emtaVehicle.variantId.slice(-3)}`
          }
          await EmtaService.updateEmtaCatalogue(this.filter)
          this.isTableLoading = false
        }
      }

      if (registered) {
        this.isTableLoading = true
        this.availableItems = await QuoteService.getAvailableLabourItems(this.filter, this.selectedTimeType)
        this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
        this.isTableLoading = false
      } else {
        this.isEmtaRegistrationModalActive = true
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableLabours()
    },
    sort(name, order) {},
    reset() {
      this.selectedButterflyCodes = []
      this.filter.query = ''
      this.filter.butterflyCode = ''
      this.filter.pageIndex = 1
      this.getAvailableLabours()
    },
    search: _debounce(function() {
      this.filter.pageIndex = 1
      this.getAvailableLabours()
    }, 500),
    cancel(value) {
      this.selectedButterflyCodes = value
      this.isButterflyModalActive = false
    },
    findItem(item, type) {
      const existingItem = this.value.find(
        i => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && i.itemType === type && !i.deleteOtherItem
      )
      return existingItem
    },
    butterflySearch() {
      this.isButterflyModalActive = true
    },
    filterButterly() {
      this.isButterflyModalActive = false
      this.filter.butterflyCode = this.selectedButterflyCodes.join()
      this.getAvailableLabours()
    },
    emtaTest() {
      this.isEmtaVehicleSelectionModalActive = !this.isEmtaVehicleSelectionModalActive
    },
    registerEmta() {
      this.isEmtaRegistrationModalActive = false
      this.isEmtaVehicleSelectionModalActive = true
    },
    cancelEmta() {
      this.isEmtaRegistrationModalActive = false
    },
    async isEmtaRegistered() {
      const result = await EmtaService.isEmtaRegistered()
      return result
    },
    selectEmtaVehicle() {
      this.getAvailableLabours()
      this.isEmtaVehicleSelectionModalActive = false
    },
    cancelEmtaVehicle() {
      this.isEmtaVehicleSelectionModalActive = false
    },
    addNewItem(item) {
      this.$emit('add-item', item)
    }
  }
}
</script>
