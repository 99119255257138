<template>
  <div class="tile is-parent">
    <article class="tile is-child box">
      <div class="columns">
        <div class="column">
          <p class="title">Labour</p>
        </div>

        <div class="column">
          <div class="control is-pulled-right">
            <button class="button is-primary tooltip is-tooltip"
              data-tooltip="Add new labour"
              @click="isAvailableItemsModuleActive = !isAvailableItemsModuleActive"
              :disabled="!timesType || !bodyId">
              <span class="icon">
                <i class="mdi"
                  :class="{ 'mdi-plus' : !isAvailableItemsModuleActive,
                            'mdi-close' : isAvailableItemsModuleActive }" />
              </span>
              <span>{{ isAvailableItemsModuleActive ? 'Close' : 'Add' }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="timesType && bodyId && isAvailableItemsModuleActive">
        <quote-template-available-labours
          v-model="items"
          :labour-type="timesType.replace('-', '').toLowerCase()"
          :is-ntar="isNtar"
          :is-ltar="isLtar"
          :body-id="bodyId"
          @add-item="activeLaboutItemTypeModal" />

        <hr>
      </div>

      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :is-loading="isTableLoading"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        :total-rows="totalRows"
        :show-pagination="false"
        @sort="sort"
        :draggable="true">

        <draggable v-model="items"
          v-bind="{chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          :tag="'tbody'"
          @change="itemMoved"
          @end="endMove"
          handle=".item-drag-handle">
          <tr v-for="(item, index) in items"
            :key="index">
            <td class="has-vertical-middle">{{ item.itemNo }}</td>
            <td>
              <input v-model="item.itemDesc"
                type="text"
                class="input">
            </td>
            <td class="has-vertical-middle">
              <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                <input v-model="item.reportOnly"
                  type="checkbox">
                <div class="state p-info">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </td>
            <td class="has-vertical-middle">
              <span class="item-type-tag"
                :class="`tag is-${item.itemType.toLowerCase()}`">
                {{ item.itemType }}
              </span>
            </td>
            <td class="has-vertical-middle has-text-centered is-content-width">
              <div class="icon-container items-center">
                <button class="button is-danger is-small is-inverted mr-1"
                  @click="deleteItem(item)">
                  <span class="icon is-medium">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </button>
                <button class="button is-primary is-small is-inverted item-drag-handle">
                  <span class="icon is-small">
                    <i class="mdi mdi-drag-vertical mdi-24px" />
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </draggable>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>

      <quote-template-labour-type-modal
        :active.sync="isLabourTypeModalActive"
        :selected-types.sync="selectedLabourItemTypes"
        @add="addNewItem"
        v-model="items">
        <p slot="text-title">Add Labour Item Type</p>
      </quote-template-labour-type-modal>
    </article>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import QuoteTemplateLabourTypeModal from '@/components/QuoteTemplateModal/QuoteTemplateLabourTypeModal'
import { LabourColumns } from './columns.js'
import { ItemCategoryTypes } from '@/enums'
import QuoteTemplateAvailableLabours from './QuoteTemplateAvailableLabours.vue'
import StoreMixin from './storeMixin'
import QuoteTemplateItemMixin from './QuoteTemplateItemMixin'
import draggable from 'vuedraggable'
import { QuoteTemplateItemModel } from '@/classes/viewmodels'
import Guid from '@/components/Guid'
import QuoteTemplateService from '@/services/QuoteTemplateService'

export default {
  name: 'QuoteTemplateLabourView',
  components: {
    BulmaTable,
    QuoteTemplateLabourTypeModal,
    QuoteTemplateAvailableLabours,
    draggable
  },
  mixins: [ StoreMixin, QuoteTemplateItemMixin ],
  data() {
    return {
      isLabourTypeModalActive: false,
      itemCategoryTypes: [
        ItemCategoryTypes.RR,
        ItemCategoryTypes.REP,
        ItemCategoryTypes.MECH,
        ItemCategoryTypes.PAINT
      ],
      newItem: null,
      selectedLabourType: null,
      selectedLabourItemTypes: null
    }
  },
  computed: {
    columns() {
      return LabourColumns
    },
    filterKey() {
      return this.$userInfo ? `${this.$userInfo.sessionId}|${this.$route.meta.fkey}` : ''
    },
    bodyId() {
      return this.templateBodyId
    },
    timesType() {
      return this.templateTimesType
    },
    isLtar() {
      return this.timesType === 'LTAR'
    },
    isNtar() {
      return this.timesType === 'NTAR'
    }
  },
  watch: {
    items: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.checkNtarLtarItems()
          this.checkNonNtarLtarItems()
        }
      },
      deep: true
    }
  },
  methods: {
    async activeLaboutItemTypeModal(item) {
      this.isLabourTypeModalActive = true
      this.newItem = item
    },
    async addNewItem() {
      if (!this.selectedLabourItemTypes.length > 0) return

      const currentMaxSortIndex = Math.max(...this.items.map((i) => i.sortNo))
      let newIndex = 1
      let newItems = []
      for (let i = 0; i < this.selectedLabourItemTypes.length; i++) {
        let newItemData = {
          itemId: Guid.empty(),
          tempateId: this.$route.params.entityId,
          itemNo: this.newItem.itemNo,
          itemDesc: this.newItem.description,
          itemType: this.selectedLabourItemTypes[i],
          sortNo: this.items.length > 0 ? currentMaxSortIndex + newIndex : 0 + i,
          timesType: this.newItem.timesType,
          reportOnly: false

        }
        const newItem = new QuoteTemplateItemModel(newItemData)

        const existingItem = this.items.some(item => item.itemNo == newItem.itemNo && item.itemType == newItem.itemType)
        if (existingItem) {
          this.$notification.openNotificationWithType('warning', 'Labour', 'Item is already in selected list. Item not added.')
          return
        }
        newItems.push(newItem)
        newIndex++
      }

      await QuoteTemplateService.insertNewQuoteTemplateItems(this.templateId, newItems)
      await this.getQuoteTemplateItems()
    },
    checkNtarLtarItems() {
      const NtarLtar = this.items.filter(i => i.timesType == 'NTAR' || i.timesType == 'LTAR')
      NtarLtar.length > 0 ? this.flagNtarLtarItems(true) : this.flagNtarLtarItems(false)
    },
    checkNonNtarLtarItems() {
      const nonNtarLtar = this.items.filter(i => i.timesType !== 'NTAR' && i.timesType !== 'LTAR')
      nonNtarLtar.length > 0 ? this.flagNonNtarLtarItems(true) : this.flagNonNtarLtarItems(false)
    }
  }
}
</script>
