import _debounce from 'lodash/debounce'
import _orderBy from 'lodash/orderBy'
import QuoteTemplateService from '@/services/QuoteTemplateService'

export default {
  data() {
    return {
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 100
      },
      isAvailableItemsModuleActive: false,
      isTableLoading: false,
      items: [],
      templateId: this.$route.params.entityId,
      totalRows: 0
    }
  },
  watch: {
    items: {
      handler: async function (newVal, oldVal) {
        if (oldVal.length > 0 && newVal.length > 0 && oldVal.length == newVal.length) {
          await this.autoUpdate(newVal)
        }
      },
      deep: true
    }
  },
  async created() {
    await this.getQuoteTemplateItems()
    this.initializeAutoUpdate()
  },
  methods: {
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },

    async endMove() {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
    },

    async getQuoteTemplateItems() {
      this.isTableLoading = true
      const templateItems = await QuoteTemplateService.getSortedQuoteTemplateItems(this.$route.params.entityId)

      this.items = templateItems.filter((i) => this.itemCategoryTypes.some((type) => i.itemType === type))

      this.totalRows = this.items.length > 0 ? this.items.length : 0
      this.isTableLoading = false
    },

    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        try {
          await this.updateItems(value)
          this.updateNotification(true)
        } catch (e) {
          this.updateNotification(false)
        }
      }, 3000)
    },

    itemMoved() {
      let sortNo = Math.min(...this.items.map((i) => i.sortNo))
      this.items.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },

    async sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order

      this.items = _orderBy(this.items, [this.filter.sortColumn, 'itemDesc'], [this.filter.sortOrder])
      this.itemMoved()
    },

    async deleteItem(item) {
      await QuoteTemplateService.deleteQuoteTemplateItem(item)
      await this.getQuoteTemplateItems()
      this.itemMoved()
    },

    async updateItems() {
      await QuoteTemplateService.updateQuoteTemplateItems(this.items)
    },

    updateNotification(status) {
      if (status) {
        return this.$toast.open({
          message: 'Template saved',
          type: 'is-success'
        })
      }

      return this.$toast.open({
        message: 'Failed to save the template',
        type: 'is-danger',
        duration: 5000
      })
    }
  },

  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    if (this.items.length > 0) {
      try {
        await this.updateItems(this.items)
        this.updateNotification(true)
      } catch(e) {
        this.updateNotification(false)
      }
    }
    next()
  }
}
